import React, { useState, useEffect } from "react";
// import Axios from "axios";
// import PropTypes from "prop-types";
// import { Link } from "react-router-dom";
// import { API_BASE_URL } from "../Config/Config";


const AddQuotation = () => {

    const [userDataSession, setUserDataSession] = useState([]);
    const [formattedDate, setFormattedDate] = useState("");
    const [loading, setLoading] = useState("");
    const [firstData, setFirstData] = useState(null);
    const [viewProducts, setViewProducts] = useState([]);

  // #region Session userdetails
  useEffect(() => {
    const userDataString = sessionStorage.getItem("userData");
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      setUserDataSession(userData);
    } else {
      console.log("User data not found in sessionStorage");
    }
  }, []);


  return (
    <div>
    
      <div
        className="offcanvas offcanvas-end"
        tabIndex={-1}
        id="offcanvasRightAddQuote"
        aria-labelledby="offcanvasRightLabel"
        style={{ minWidth: "55%", maxWidth: "55%" }}
      >
        <div className="offcanvas-header">
          <h4 id="offcanvasRightLabel" className="me-3">Generate Quotation</h4>

          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <hr />
        <div className="offcanvas-body">
          <form>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-blocks">
                  <label>Quote Date</label>
                  <div className="input-groupicon calender-input">
                    <input
                      type="date"
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-blocks me-3">
                    <label className="form-label">Discount Type</label>
                    <select
                      className="form-select"
                    >
                        <option>Choose type</option>
                        <option>Percentage</option>
                        <option>Fixed</option>
                    </select>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-blocks me-3">
                    <label className="form-label">Discount Amount</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter discount amount"
                    />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-blocks me-3">
                    <label className="form-label">Advance Amount</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter advance amount"
                    />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-blocks">
                  <label>Expected Delivery</label>
                  <div className="input-groupicon calender-input">
                    <input
                      type="date"
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="input-blocks me-3">
                    <label className="form-label">Purchase Products</label>
                    <select
                      className="form-select"
                    >
                        <option>Choose Purchase Order</option>
                        
                    </select>
                </div>
              </div>
            <div className="row mb-2">
              <div className="col-lg-12">
                <div className="modal-body-table">
                  <div className="table-responsive" style={{ maxHeight: '25rem', overflowY: 'auto' }}>
                    <table className="table  dataew">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Product</th>
                          <th>Price</th>
                          <th>Discount</th>
                          <th>Tax</th>
                          <th>Refund Qty</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr>
                            <td colSpan="12" className="text-center">
                              Loading...
                            </td>
                          </tr>
                        ) : viewProducts.length !== 0 ? (
                          viewProducts.map((item, index) => (
                            <tr key={index}>
                              <th>{index + 1}</th>
                              <td>{new Date(item.CreatedOn).toLocaleDateString('en-GB')}</td>
                              <td>{item.MobileNo}</td>
                              <td className="text-info">{item.PaymentTypeName}</td>
                              <td>{item.CardNo}</td>
                              <td className="fw-bold text-success">{item.PaidAmount.toLocaleString('en-IN')}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="8" className="text-center">
                              No Data available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div>
                    <label className="form-label">Notes</label>
                    <textarea
                        className="form-control"
                        placeholder="Enter notes..."
                    ></textarea>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-3">
                <button 
                    className="btn btn-primary me-2"
                >Save as Draft</button>
                <button
                    className="btn btn-success"
                >Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddQuotation;
