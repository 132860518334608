import React, { useEffect, useState } from "react";
import Axios from "axios";
// import Select from "react-select";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Base from "../Config/Base";
import { API_BASE_URL } from "../Config/Config";


export default function SupplierRegister() {
  const [userDataSession, setUserDataSession] = useState([]);
  const [addSubmitBtnLoading, setAddSubmitBtnLoading] = useState(false);
  const [addAddressType, setAddAddressType] = useState("Office");
  const [addAccountType, setAddAccountType] = useState("Savings");
  const navigate = useNavigate();

  useEffect(() => {
    const userDataString = sessionStorage.getItem("userData");
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      setUserDataSession(userData);
      console.log(userData);
      navigate("/supplier-registration");
    } else {
      console.log("User data not found in sessionStorage");
    }
  }, []);

  const [addFormData, setAddFormData] = useState({
    orgid: userDataSession.OrgId,
    userid: userDataSession.Id || 0,
    Supplier: {
      SupplierName: userDataSession.SupplierName,
      RegistrationNo: userDataSession.RegistrationNo,
      GSTIN: userDataSession.GSTIN,
      Status: "Draft",
      PayTermDays: userDataSession.PayTermDays,
      ContactName: userDataSession.ContactName,
      EmailId: userDataSession.EmailId,
      PhoneNo: userDataSession.PhoneNo,
      AlternatePhoneNo: userDataSession.AlternatePhoneNo,
      Website: userDataSession.Website,
      AddressType: userDataSession.addAddressType,
      AddressLine1: userDataSession.AddressLine1,
      AddressLine2: userDataSession.AddressLine2,
      City: userDataSession.City,
      State: userDataSession.State,
      Country: "India",
      ZIPCode: userDataSession.ZIPCode,
      Notes: userDataSession.Notes,
      IsIGST: "",
      IsVendor: 1,
      IsActive: 1,
      CreatedBy: userDataSession.Id || 0,
    },
    bank: [
      {
        Id: userDataSession.Id || 0,
        BankName: userDataSession.BankName || "",
        AccountNumber: userDataSession.AccountNumber || "",
        IFSCCode: userDataSession.IFSCCode || "",
        AccountType: userDataSession.AccountType || "",
        IsActive: 1,
      },
    ],
  });

  const handleAddAddressSelectChange = (event) => {
    setAddAddressType(event.target.value);
  };

  const handleAddAccountSelectChange = (event) => {
    setAddAccountType(event.target.value);
  };
  const handleAddInputChange = async (e) => {
    const { name, value } = e.target;
    const [objectName, key] = name.split(".");

    // Update the state based on the input name
    if (objectName && key) {
      setAddFormData((prevState) => {
        const updatedData = {
          ...prevState,
          [objectName]: {
            ...prevState[objectName],
            [key]: value,
          },
        };

        // Send updated data to the server

        return updatedData;
      });
    } else {
      setUserDataSession((prevState) => {
        const updatedData = {
          ...prevState,
          [name]: value,
        };

        // Send updated data to the server

        return updatedData;
      });
    }
  };

  const handleAddSubmit = (e) => {
    e.preventDefault();
    console.log(userDataSession);
    setAddSubmitBtnLoading(true);
    console.log("here");
    console.log(addFormData.Supplier);
    const formattedData = {
      orgid: userDataSession.OrgId,
      userid: 0,
      Supplier: {
        SupplierName: userDataSession.SupplierName,
        RegistrationNo: userDataSession.RegistrationNo,
        GSTIN: userDataSession.GSTIN,
        Status: "Draft",
        Id: userDataSession.Id,
        PayTermDays: userDataSession.PayTermDays,
        ContactName: userDataSession.ContactName,
        EmailId: userDataSession.EmailId,
        PhoneNo: userDataSession.PhoneNo,
        AlternatePhoneNo: userDataSession.AlternatePhoneNo,
        Website: userDataSession.Website,
        AddressType: userDataSession.addAddressType,
        AddressLine1: userDataSession.AddressLine1,
        AddressLine2: userDataSession.AddressLine2,
        City: userDataSession.City,
        State: userDataSession.State,
        Country: "India",
        ZIPCode: userDataSession.ZIPCode,
        Notes: userDataSession.Notes,
        IsIGST: "",
        IsVendor: 1,
        IsActive: 1,
        CreatedBy: 0,
      },
      bank: [
        {
          Id: userDataSession.BankId,
          BankName: userDataSession.BankName || "",
          AccountNumber: userDataSession.AccountNumber || "",
          IFSCCode: userDataSession.IFSCCode || "",
          AccountType: userDataSession.AccountType || "",
          IsActive: 1,
        },
      ],
    };

    console.log(formattedData, "sending data to add API.");

    Axios.post(`${API_BASE_URL}Inventory/InsertSuppliers`, formattedData)
      .then((response) => {
        console.log(response, "response data...");
        setAddSubmitBtnLoading(false);

        // Check for error in the response
        if (response.data.error) {
          // If there's an error, show the error message
          Swal.fire({
            title: "Error",
            text: response.data.error,
            icon: "error",
          });
        } else {
          // If no error, show success message
          Swal.fire({
            title: "Supplier registered successfully.",
            icon: "success",
          }).then((result) => {
            // if (result.isConfirmed || result.isDismissed) {
            //   window.location.reload();
            // }
          });
        }
      })
      .catch((error) => {
        setAddSubmitBtnLoading(false);

        console.error("Error adding supplier:", error);
        Swal.fire({
          title: "Error",
          text: "An unexpected error occurred.",
          icon: "error",
        });
      });
  };

  return (
    <Base>
          <div className="d-flex">
            <a href="/dashboard">
              <i className="fa-solid fa-house me-1 mt-1 text-primary"></i>
            </a>
            <i className="fa-solid fa-angles-right me-1 mt-1"></i>
            <h4>Supplier Registration</h4>
          </div>
          <div className="custom-modal-two mt-3">
            <div className="modal-contnt">
              <div className="page-wrapper-new p-0">
                <div className="content">
                  <div className="modal-body custom-modal-body">
                    <ul
                      className="nav nav-tabs tab-style-2 nav-justified mb-3 d-sm-flex d-block"
                      id="myTab1"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="order-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#order-tab-pane-add"
                          type="button"
                          role="tab"
                          aria-controls="home-tab-pane"
                          aria-selected="true"
                        >
                          <i className="fa-regular fa-address-book me-1 align-middle"></i>
                          Personal Info
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="confirmed-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#confirm-tab-pane-add"
                          type="button"
                          role="tab"
                          aria-controls="profile-tab-pane"
                          aria-selected="false"
                        >
                          <i className="fa-solid fa-building-columns me-1 align-middle"></i>
                          Bank Details
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="shipped-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#shipped-tab-pane-add"
                          type="button"
                          role="tab"
                          aria-controls="contact-tab-pane"
                          aria-selected="false"
                        >
                          <i className="fa-solid fa-house-user me-1 align-middle"></i>
                          Address
                        </button>
                      </li>
                    </ul>
                    <form onSubmit={handleAddSubmit}>
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active text-muted"
                          id="order-tab-pane-add"
                          role="tabpanel"
                          aria-labelledby="order-tab"
                          tabIndex={0}
                        >
                          <div className="row">
                            <div className="col-lg-4">
                              <div className="input-blocks">
                                <label>Supplier Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="SupplierName"
                                  value={userDataSession.SupplierName || ""}
                                  onChange={handleAddInputChange}
                                  placeholder="Organization name"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="input-blocks">
                                <label>Email</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  name="EmailId"
                                  value={userDataSession.EmailId || ""}
                                  onChange={handleAddInputChange}
                                  placeholder="Enter email"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="input-blocks">
                                <label>Phone</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="PhoneNo"
                                  value={userDataSession.PhoneNo || ""}
                                  onChange={handleAddInputChange}
                                  placeholder="Enter phone no"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="input-blocks">
                                <label>Contact Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="ContactName"
                                  value={userDataSession.ContactName || ""}
                                  onChange={handleAddInputChange}
                                  placeholder="Enter contact name"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="input-blocks">
                                <label>Alternate Phone No</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="AlternatePhoneNo"
                                  value={userDataSession.AlternatePhoneNo || ""}
                                  onChange={handleAddInputChange}
                                  placeholder="Enter alternate no"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="input-blocks">
                                <label>Reg No</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="RegistrationNo"
                                  value={userDataSession.RegistrationNo || ""}
                                  onChange={handleAddInputChange}
                                  placeholder="Enter registration no"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="input-blocks">
                                <label>Payterm</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="PayTermDays"
                                  value={userDataSession.PayTermDays || ""}
                                  onChange={handleAddInputChange}
                                  placeholder="Enter payterm"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="input-blocks">
                                <label>GSTIN</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="GSTIN"
                                  value={userDataSession.GSTIN}
                                  onChange={handleAddInputChange}
                                  placeholder="Enter GST no"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="input-blocks">
                                <label>Website</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="Website"
                                  value={userDataSession.Website}
                                  onChange={handleAddInputChange}
                                  placeholder="Enter GST no"
                                />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="input-blocks">
                                <label>Description</label>
                                <textarea
                                  type="text"
                                  className="form-control"
                                  name="Notes"
                                  value={userDataSession.Notes || ""}
                                  onChange={handleAddInputChange}
                                  placeholder="Enter notes..."
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade text-muted"
                          id="confirm-tab-pane-add"
                          role="tabpanel"
                          aria-labelledby="confirmed-tab"
                          tabIndex={0}
                        >
                          <div className="row">
                            <div className="col-lg-4 col-sm-10 col-10">
                              <div className="input-blocks">
                                <label>Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="BankName"
                                  value={userDataSession.BankName || ""}
                                  onChange={handleAddInputChange}
                                  placeholder="Enter bank name"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-10 col-10">
                              <div className="input-blocks">
                                <label>Account No</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="AccountNumber"
                                  value={userDataSession.AccountNumber || ""}
                                  onChange={handleAddInputChange}
                                  placeholder="Enter account number"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-10 col-10">
                              <div className="input-blocks">
                                <label>IFSC Code</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="IFSCCode"
                                  value={userDataSession.IFSCCode || ""}
                                  onChange={handleAddInputChange}
                                  placeholder="Enter IFSC code"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-10 col-10">
                              <div className="input-blocks">
                                <label>Account Type</label>
                                <select
                                  className="form-select"
                                  name="AddAccountType"
                                  value={addAccountType}
                                  onChange={handleAddAccountSelectChange}
                                >
                                  <option value="Savings">Savings</option>
                                  <option value="Current">Current</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade text-muted"
                          id="shipped-tab-pane-add"
                          role="tabpanel"
                          aria-labelledby="shipped-tab"
                          tabIndex={0}
                        >
                          <div className="row">
                            <div className="col-lg-4 col-sm-10 col-10">
                              <div className="input-blocks">
                                <label>Country</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value="India"
                                  style={{ cursor: "not-allowed" }}
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-10 col-10">
                              <div className="input-blocks">
                                <label>City</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="City"
                                  value={userDataSession.City || ""}
                                  onChange={handleAddInputChange}
                                  placeholder="Enter city"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-10 col-10">
                              <div className="input-blocks">
                                <label>State</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="State"
                                  name="State"
                                  value={userDataSession.State || ""}
                                  onChange={handleAddInputChange}
                                  placeholder="Enter state"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="input-blocks">
                                <label>ZipCode</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="ZIPCode"
                                  value={userDataSession.ZIPCode || ""}
                                  onChange={handleAddInputChange}
                                  placeholder="Enter pipncode"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="input-blocks">
                                <label>Address Type</label>
                                <select
                                  className="form-select"
                                  name="AddressType"
                                  value={addAddressType}
                                  onChange={handleAddAddressSelectChange}
                                >
                                  <option value="Office">Office</option>
                                  <option value="Warehouse">Warehouse</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="mb-0 input-blocks">
                                <label className="form-label">Address</label>
                                <textarea
                                  className="form-control mb-1"
                                  placeholder="Enter full address"
                                  name="AddressLine1"
                                  value={userDataSession.AddressLine1 || ""}
                                  onChange={handleAddInputChange}
                                />
                                <p>Maximum 600 Characters</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <button type="submit" className="btn btn-submit">
                          {addSubmitBtnLoading ? "Submitting..." : "Submit"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </Base>
       
  );
}
