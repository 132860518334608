import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SupplierRegister from './Components/Auth/SupplierRegister';
import SignIn from './Components/Auth/SignIn';
import SignUp from './Components/Auth/Signup';
import Purchases from './Components/Purchases/Purchases';
import Payments from './Components/Payments/Payments';
import Quotations from './Components/Quotations/Quotations';
import Dashboard from './Components/Dashboard/Dashboard';


function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/supplier-registration" element={<SupplierRegister />} />
          <Route path="/purchases" element={<Purchases />} />
          <Route path="/payments" element={<Payments />} />
          <Route path="/quotations" element={<Quotations />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
