
import React, { useState, useEffect } from 'react';
import IbizLogo from "../../Assests/Images/41114.png";
import { Link } from 'react-router-dom';
import UserIcon from '../../Assests/Images/usericon.svg';
import Axios from "axios";
import { API_BASE_URL } from './Config';


const Base = ({ children }) => {

    const [userDataSession, setUserDataSession] = useState([]);
    const [notifysData, setNotifysData] = useState([]);
    const [expandedIndex, setExpandedIndex] = useState(null);

    useEffect(() => {
        const userDataString = sessionStorage.getItem("userData");
        if (userDataString) {
            const userData = JSON.parse(userDataString);
            setUserDataSession(userData);
            // console.log(userData);
        } else {
            console.log("User data not found in sessionStorage");
        }
    }, []);

    useEffect(() => {
        if (userDataSession && userDataSession.OrgId){
            const api = `${API_BASE_URL}Misc/getNotifications?UserId=${userDataSession.UserId}&OrgId=${userDataSession.OrgId}`;
            Axios.get(api)
                .then(response => {
                    if (response.data.Status) {
                        setNotifysData(response.data.ResultData);
                        // console.log(response.data.ResultData, 'notify data ......');
                    } else {
                        console.error('Error fetching data:', response.data);
                        setNotifysData([]);
                    }
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    }, [userDataSession]);

    const toggleAccordion = (index, event) => {
        event.stopPropagation();
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    const handleLogout = () => {

        sessionStorage.removeItem("userData");
        sessionStorage.removeItem("otp")

        setUserDataSession(null);
        window.location.href = "/";
        console.log("User logged out");
    };

    return (
        <div className="main-wrapper">
            <div className="header">
                <div className="header-left active">
                    <a href="/dashboard" className="logo logo-normal">
                        <img src={IbizLogo} alt="" />
                    </a>
                    <a href="/dashboard" className="logo logo-white">
                        <img src={IbizLogo} alt="" />
                    </a>
                    <a href="/dashboard" className="logo-small">
                        <img src={IbizLogo} alt="" />
                    </a>
                    <a id="toggle_btn">
                        <i data-feather="chevrons-left" className="feather-16"></i>
                    </a>
                </div>

                <ul className="nav user-menu">
                    <li className="nav-item nav-searchinputs">
                        <div className="top-nav-search">
                            <a className="responsive-search">
                                <i className="fa fa-search"></i>
                            </a>
                            <form action="#" className="dropdown"></form>
                        </div>
                    </li>

                    <li className="nav-item dropdown nav-item-box">
                        <Link
                            to="#"
                            className="dropdown-toggle nav-link"
                            data-bs-toggle="dropdown"
                        >
                            <i className="fa-regular fa-bell fa-shake"></i>
                            <span 
                                className={`badge rounded-pill ${notifysData.length > 0 ? 'blinking-badge' : ''}`}
                                style={{ width: '1.2rem', height: '1.2rem' }}
                            >
                                {notifysData.length || 0}
                            </span>
                        </Link>
                        <div className="dropdown-menu notifications">
                            <div className="topnav-dropdown-header">
                                <span className="notification-title">Notifications</span>
                                <Link to="#" className="clear-noti">Clear All</Link>
                            </div>

                            <div className="noti-content">
                                <ul className="notification-list">
                                {notifysData && notifysData.map((item, index) => {
                                    const getTimeDifference = (createdOn) => {
                                    const now = new Date();
                                    const createdDate = new Date(createdOn);

                                    const diffInMs = now - createdDate;
                                    const diffInMinutes = Math.floor(diffInMs / 1000 / 60);
                                    const diffInHours = Math.floor(diffInMinutes / 60);
                                    const diffInDays = Math.floor(diffInHours / 24);

                                    if (diffInMinutes < 60) {
                                        return `${diffInMinutes} mins ago`;
                                    } else if (diffInHours < 24) {
                                        return `${diffInHours} hours ago`;
                                    } else {
                                        return `${diffInDays} days ago`;
                                    }
                                    };

                                    return (
                                    <li  className={`notification-message ${expandedIndex === index ? 'active' : ''}`}
                                        key={index}
                                        onClick={(event) => toggleAccordion(index, event)}
                                    >
                                        <Link to="#">
                                            <div className="media d-flex">
                                                <span className="avata flex-shrink-0">
                                                {expandedIndex === index ? (
                                                    <i className="fa-regular fa-envelope-open text-primary me-2 fs-6"></i>
                                                ) : (
                                                    <i className="fa-regular fa-envelope me-2 fs-6"></i>
                                                )}
                                                </span>
                                                <div className="media-body flex-grow-1">
                                                    <p className="noti-details">
                                                        <span className="noti-title">{item.ScreenText}</span>
                                                    </p>
                                                    <p className="noti-time">
                                                        <span className="notification-time">
                                                            {getTimeDifference(item.CreatedOn)}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </Link>

                                        {expandedIndex === index && (
                                        <div className="accordion-body ms-3">
                                            {item.EmailBody ? (
                                            <p
                                                dangerouslySetInnerHTML={{
                                                __html: item.EmailBody.replace(/\r?\n|\r/g, '<br />'),
                                                }}
                                            />
                                            ) : (
                                            <p>No Body available</p>
                                            )}
                                        </div>
                                        )}
                                    </li>
                                    );
                                })}
                                </ul>
                            </div>
                            
                            <div className="topnav-dropdown-footer">
                                <Link to="#">View all Notifications</Link>
                            </div>
                        </div>
                    </li>

                    <li className="nav-item dropdown has-arrow main-drop">
                        <a className="dropdown-toggle nav-link userset" data-bs-toggle="dropdown">
                            <span className="user-info">
                                <span className="user-letter text-primary fs-3"><i className="fa fa-user-circle"></i></span>
                                {/* <span className="user-letter text-primary fs-3">{userDataSession?.SupplierName?.charAt(0)}</span> */}
                                <span className="user-detail">
                                    <span className="user-name text-warning">
                                        {userDataSession.SupplierName}
                                    </span>
                                    <h5 className="profilesets">Vendor</h5>
                                    <span className="user-role"></span>
                                </span>
                            </span>
                        </a>
                        <div className="dropdown-menu menu-drop-user">
                            <div className="profilename">
                                <div className="profileset">
                                    <span className="user-img">
                                        <img src={UserIcon} alt="usericon" />
                                        {/* <span className="status online"></span> */}
                                    </span>
                                    <div className="profilesets">
                                        <h5 className='text-warning'>{userDataSession.SupplierName}</h5>
                                        <h6>Vendor</h6>
                                    </div>
                                </div>
                                <hr className="m-0" />
                                <Link to="/supplier-registration">
                                    <a
                                        className="dropdown-item logout pb-0 text-secondary"
                                    >
                                       <i className="fa-regular fa-user me-2"></i>
                                        Profile
                                    </a>
                                </Link>
                                <Link to="/purchases">
                                    <a
                                        className="dropdown-item logout pb-0 text-secondary"
                                    >
                                       <i className="fa-solid fa-store me-2"></i>
                                        Purchases
                                    </a>
                                </Link>
                                <Link to="/payments">
                                    <a
                                        className="dropdown-item logout pb-0 text-secondary"
                                    >
                                       <i className="fa-solid fa-coins me-2"></i>
                                        Payments
                                    </a>
                                </Link>
                                <Link to="/quotations">
                                    <a
                                        className="dropdown-item logout pb-0 text-secondary"
                                    >
                                       <i className="fa-solid fa-calculator me-2"></i>
                                       Quotations
                                    </a>
                                </Link>
                                <hr className="m-0" />
                                <Link to="/">
                                    <a className="dropdown-item logout pb-0" onClick={handleLogout}>
                                        <i className="fa-solid fa-right-from-bracket me-2"></i>
                                        Logout
                                    </a>
                                </Link>
                            </div>
                        </div>
                    </li>
                </ul>

                {/* Mobile */}
                <div className="dropdown mobile-user-menu">
                    <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="fa fa-ellipsis-v"></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right">
                        <a className="dropdown-item text-secondary" href="supplier-registration">
                            <i className="fa-regular fa-user me-2"></i> Profile
                        </a>
                        <a className="dropdown-item text-secondary" href="purchases">
                            <i className="fa-solid fa-store me-2"></i> Purchases
                        </a>
                        <a className="dropdown-item text-secondary" href="payments">
                            <i className="fa-solid fa-coins me-2"></i> Payments
                        </a>
                        <a className="dropdown-item text-secondary" href="quotations">
                            <i className="fa-solid fa-calculator me-2"></i>Quotations
                        </a>
                        <a className="dropdown-item text-danger" onClick={handleLogout}>
                            <i className="fa-solid fa-right-from-bracket me-2"></i> Logout
                        </a>
                    </div>
                </div>
            </div>
            <div className="page-wrapper ">
                <div className="content">
                   {children}
                </div>
            </div>
        </div>
    )
}

export default Base;