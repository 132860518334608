import React, { useState, useEffect } from "react";
import Axios from "axios";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { API_BASE_URL } from "../Config/Config";


const ViewPayment = ({ POObj }) => {

    const [userDataSession, setUserDataSession] = useState([]);
    const [formattedDate, setFormattedDate] = useState("");
    const [loading, setLoading] = useState("");
    const [firstData, setFirstData] = useState(null);
    const [viewProducts, setViewProducts] = useState([]);

  // #region Session userdetails
  useEffect(() => {
    const userDataString = sessionStorage.getItem("userData");
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      setUserDataSession(userData);
    } else {
      console.log("User data not found in sessionStorage");
    }
  }, []);

  useEffect(() => {
    if (POObj) {
      console.log(POObj, "ppoobbjj");
      if (POObj.PODate) {
        const poDate = new Date(POObj.PODate);
        const formattedPODate = poDate.toISOString().split("T")[0];
        setFormattedDate(formattedPODate);
      }
    }
  }, [POObj]);

  useEffect(() => {
    if (userDataSession && POObj && POObj.POID) {
      setLoading(true);
      const url = `${API_BASE_URL}Supplier/GetSupplierPOPaymntsVIEW?POId=${POObj.POID}`;
      Axios.get(url)
        .then((response) => {
          if (response.data.Status) {
            setViewProducts(response.data.ResultData);
            setFirstData(response.data.ResultData[0]);
            console.log(response.data.ResultData, "req details");
            setLoading(false);
          } else {
            console.error("Error fetching data:", response.data.Message);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    }
  }, [userDataSession, POObj]);

  return (
    <div>
    
      <div
        className="offcanvas offcanvas-end"
        tabIndex={-1}
        id="offcanvasRightPaymentView"
        aria-labelledby="offcanvasRightLabel"
        style={{ minWidth: "55%", maxWidth: "55%" }}
      >
        <div className="offcanvas-header">
          <h4 id="offcanvasRightLabel" className="me-3">
            View Purchase Payments (<span className="text-primary">PO-{POObj ? POObj.AutoGenTransactionNo : ""}</span>)
          </h4>

          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <hr />
        <div className="offcanvas-body">
          <form>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-blocks">
                  <label>Date</label>
                  <div className="input-groupicon calender-input">
                    <input
                      type="date"
                      className="form-control"
                      value={formattedDate}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="input-blocks me-3">
                        <label className="form-label">Status</label>
                        <input
                            className={`form-control ${POObj?.TotalAmount <= POObj?.TotalPaidAmount ? 'text-success' : 'text-primary'}`}
                            type="text"
                            value={
                                POObj?.TotalAmount <= POObj?.TotalPaidAmount
                                ? 'Completed'
                                : 'Pending'
                            }
                            readOnly
                        />
                    </div>
                </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-blocks me-3">
                    <label className="form-label">Due Amount</label>
                    <input
                        className="form-control text-danger"
                        type="text"
                        value={
                        POObj?.TotalAmount !== undefined && POObj?.TotalPaidAmount !== undefined
                            ? ((POObj.TotalAmount - POObj.TotalPaidAmount) || 0)
                                .toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                            : "0.00"
                        }
                        readOnly
                    />
                </div>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-lg-12">
                <div className="modal-body-table">
                  <div className="table-responsive" style={{ maxHeight: '25rem', overflowY: 'auto' }}>
                    <table className="table  dataew">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Payment Date</th>
                          <th>Mobile No</th>
                          <th>Payment Type</th>
                          <th>Card No</th>
                          <th>Paid Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr>
                            <td colSpan="12" className="text-center">
                              Loading...
                            </td>
                          </tr>
                        ) : viewProducts.length !== 0 ? (
                          viewProducts.map((item, index) => (
                            <tr key={index}>
                              <th>{index + 1}</th>
                              <td>{new Date(item.CreatedOn).toLocaleDateString('en-GB')}</td>
                              <td>{item.MobileNo}</td>
                              <td className="text-info">{item.PaymentTypeName}</td>
                              <td>{item.CardNo}</td>
                              <td className="fw-bold text-success">{item.PaidAmount.toLocaleString('en-IN')}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="8" className="text-center">
                              No Data available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <div className="justify-content-end d-flex mt-2">
                        <p
                            style={{ height: "1.8rem" }}
                            className="badge badge-primary fs-6 col-6"
                        >
                        Total Paid Amount:{" "}
                        <span className="fw-bold fs-5">
                            {POObj?.TotalPaidAmount !== undefined && POObj?.TotalPaidAmount !== null 
                            ? Number(POObj.TotalPaidAmount).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) 
                            : "0.00"}
                        </span>
                        </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

ViewPayment.propTypes = {
  POObj: PropTypes.object.isRequired,
};

export default ViewPayment;
