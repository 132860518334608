import { React, useEffect, useState } from "react";
import Axios from "axios";
import Base from "../Config/Base";
// import ViewPurchase from "./ViewPurchase";
import '../../Assests/CSS/custom.css';
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import AddQuotation from "./AddQuotation";
import { API_BASE_URL } from "../Config/Config";


export default function Quotations() {

    const [userDataSession, setUserDataSession] = useState([]);
    const [supplierPosData, setSupplierPOsData] = useState([]);
    const [viewPoDataObj, setViewPODataObj] = useState(null);
    const [dataLoading, setDataLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    useEffect(() => {
        const userDataString = sessionStorage.getItem("userData");
        if (userDataString) {
            const userData = JSON.parse(userDataString);
            setUserDataSession(userData);
            console.log(userData);
        } else {
            console.log("User data not found in sessionStorage");
        }
    }, []);

    useEffect(() => {
        const fetchDashboardData = async () => {
            setDataLoading(true);
            const table_api = `${API_BASE_URL}supplier/getQuotations?OrgId=${userDataSession.OrgId}&SupplierId=${userDataSession.Id}`;
            try {
                const tablesResponse = await Axios.get(table_api);
                setSupplierPOsData(tablesResponse.data.ResultData);
                console.log(tablesResponse.data, ".....dataaaaa");
            } catch (error) {
                console.error(
                    "Error fetching dashboard data:",
                    error.response ? error.response.data : error.message
                );
            } finally {
                setDataLoading(false);
            }
        };
    
        if (userDataSession && userDataSession.OrgId) {
            fetchDashboardData();
        }
    }, [userDataSession]);    

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const ViewCavasClick = (obj) => {
        setViewPODataObj(obj);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = supplierPosData.slice(indexOfFirstItem, indexOfLastItem);

    const totalItems = supplierPosData.length;

    const startItem = indexOfFirstItem + 1;
    const endItem = indexOfLastItem > totalItems ? totalItems : indexOfLastItem;

    const filteredItems = currentItems.filter(item => {
        const poNumber = String(item.AutoGenTransactionNo || '').toLowerCase();
        const poDate = String(formatDate(item.PODate) || '').toLowerCase();
        return poNumber.includes(searchQuery.toLowerCase()) || poDate.includes(searchQuery.toLowerCase());
    });

    const generatePDF = () => {
        const doc = new jsPDF();
    
        doc.setProperties({
          title: "purchases",
          author: "Your Name",
          creator: "Your Application",
        });
    
        const tableData = [];
    
        const headers = [
          "PO Number",
          "PO Date",
          "Total Amount",
          "Paid Amount",
          "Due Amount",
        ];
        tableData.push(headers);
    
        supplierPosData.forEach((item) => {
            const row = [];
            row.push(
                `PO-${item.AutoGenTransactionNo}`,
                formatDate(item.PODate),
                (item.InvoiceAmount !== null && item.InvoiceAmount !== undefined ? item.InvoiceAmount.toLocaleString('en-IN') : '0'),
                (item.PaidAmount !== null && item.PaidAmount !== undefined ? item.PaidAmount.toLocaleString('en-IN') : '0'),
                (item.InvoiceAmount !== null && item.InvoiceAmount !== undefined && item.PaidAmount !== null && item.PaidAmount !== undefined ? (item.InvoiceAmount - item.PaidAmount).toLocaleString('en-IN') : '0'),
            );
            tableData.push(row);
        });
    
        doc.autoTable({
          head: [headers],
          body: tableData.slice(1),
        });
    
        doc.save("Purchases.pdf");
    };
    
    const generateEXCEL = () => {
        if (supplierPosData.length === 0) {
            return;
        }
    
        const wb = XLSX.utils.book_new();
    
        const headers = [
            "PO Number",
            "PO Date",
            "Total Amount",
            "Paid Amount",
            "Due Amount",
        ];
    
        const wsData = supplierPosData.map((item) => ({
            "PO Number": `PO-${item.AutoGenTransactionNo}`,
            "PO Date": formatDate(item.PODate),
            "Total Amount": (item.InvoiceAmount !== null && item.InvoiceAmount !== undefined ? item.InvoiceAmount.toLocaleString('en-IN') : '0'),
            "Paid Amount": (item.PaidAmount !== null && item.PaidAmount !== undefined ? item.PaidAmount.toLocaleString('en-IN') : '0'),
            "Due Amount": ((item.InvoiceAmount !== null && item.InvoiceAmount !== undefined && item.PaidAmount !== null && item.PaidAmount !== undefined) ? 
                (item.InvoiceAmount - item.PaidAmount).toLocaleString('en-IN') : '0'),
        }));        
    
        wsData.unshift({
            "PO Number": headers[0],
            "PO Date": headers[1],
            "Total Amount": headers[2],
            "Paid Amount": headers[3],
            "Due Amount": headers[4],
        });
    
        const ws = XLSX.utils.json_to_sheet(wsData, { skipHeader: true });
    
        XLSX.utils.book_append_sheet(wb, ws, "Purchases");
    
        XLSX.writeFile(wb, "purchases.xlsx");
    };

    return (
        <Base>
            <div className="d-flex mb-3">
                <a href="/dashboard">
                    <i className="fa-solid fa-house me-1 mt-1 text-primary"></i>
                </a>
                <i className="fa-solid fa-angles-right me-1 mt-1"></i>
                <h4>Quotations</h4>
                <button
                    className="d-flex ms-auto btn btn-primary"
                    data-bs-toggle="offcanvas" 
                    data-bs-target="#offcanvasRightAddQuote" 
                    aria-controls="offcanvasRightAddQuote"
                >Generate Quote</button>
            </div>
            <div className="page-wrapper ">
                <div className="content">
                    <div className="row" style={{ marginTop: '-6rem' }}>
                        <div className="col-sm-12 col-md-12 col-xl-12 d-flex">
                            <div className="card flex-fill default-cover w-100 mb-4">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                        <i 
                                            className="fa-regular fa-file-pdf me-3 text-danger fs-4"
                                            style={{ cursor: 'pointer' }}
                                            title="Download PDF"
                                            onClick={generatePDF}
                                        ></i>
                                        <img 
                                            src="assets/img/icons/excel.svg" 
                                            style={{ width: '1.8rem', cursor: 'pointer' }}
                                            title="Download Excel"
                                            onClick={generateEXCEL}
                                        />
                                    </div>
                                    <div className="dropdown">
                                        <input
                                            className="form-control"
                                            placeholder="Search..."
                                            style={{ height: '2rem' }}
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>S.No</th>
                                                    <th>PO Number</th>
                                                    <th>PO Date</th>
                                                    <th>Total Amount</th>
                                                    <th>Paid Amount</th>
                                                    <th>Due Amount</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dataLoading ? (
                                                    <tr>
                                                        <td colSpan="7" className="text-center">
                                                            <div className="loader m-auto"></div>
                                                        </td>
                                                    </tr>
                                                ) : filteredItems && filteredItems.length === 0 ? ( 
                                                        <tr>
                                                            <td colSpan="7" className="text-center">
                                                                <span>No data available</span>
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                    filteredItems && filteredItems?.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1 + indexOfFirstItem}</td>
                                                            <td className="fw-semibold">PO-{item.AutoGenTransactionNo}</td>
                                                            <td>{formatDate(item.PODate)}</td>
                                                            <td className="text-info fw-bold">{item.InvoiceAmount || 0}</td>
                                                            <td className="text-success fw-bold">{item.PaidAmount || 0}</td>
                                                            <td className="text-danger fw-bold">{(item.InvoiceAmount) - (item.PaidAmount)}</td>
                                                            <td class="">
                                                                <a class="action-set" href="javascript:void(0);" data-bs-toggle="dropdown" aria-expanded="true">
                                                                    <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                                </a>
                                                                <ul class="dropdown-menu">
                                                                    <li 
                                                                        data-bs-toggle="offcanvas" 
                                                                        data-bs-target="#offcanvasRightPOView" 
                                                                        aria-controls="offcanvasRightPOView"
                                                                        onClick={() => ViewCavasClick(item)}
                                                                    >
                                                                        <a class="dropdown-item">
                                                                            <i className="fa-regular fa-eye me-2"></i>
                                                                            View
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    ))
                                                )}
                                            </tbody>
                                        </table>
                                        <div className="dataTables_paginate paging_simple_numbers d-flex justify-content-between align-items-center">
                                            <div className="dataTables_info mt-0" id="DataTables_Table_0_info" role="status" aria-live="polite">
                                                {startItem} - {endItem} of {totalItems} items
                                            </div>
                                            <ul className="pagination mb-0">
                                                <li className={`paginate_button page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                    <a
                                                        className="page-link"
                                                        onClick={() => setCurrentPage(currentPage - 1)}
                                                    >
                                                        <i className="fa fa-angle-left"></i>
                                                    </a>
                                                </li>
                                                {Array.from({ length: Math.ceil(totalItems / itemsPerPage) }, (_, index) => (
                                                    <li className={`paginate_button page-item ${currentPage === index + 1 ? 'active' : ''}`} key={index}>
                                                        <a
                                                            className="page-link"
                                                            onClick={() => setCurrentPage(index + 1)}
                                                        >
                                                            {index + 1}
                                                        </a>
                                                    </li>
                                                ))}
                                                <li className={`paginate_button page-item ${currentPage === Math.ceil(totalItems / itemsPerPage) ? 'disabled' : ''}`}>
                                                    <a
                                                        className="page-link"
                                                        onClick={() => setCurrentPage(currentPage + 1)}
                                                    >
                                                        <i className="fa fa-angle-right"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <ViewPurchase POObj={viewPoDataObj} /> */}
                <AddQuotation />
            </div>
        </Base>
    );
}
