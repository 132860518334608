import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PendingPayment from '../../Assests/Images/pendingpayment.svg'
import PaidPayment from '../../Assests/Images/paidpayment.svg'
import PurchaseServed from '../../Assests/Images/purchaseserved.svg'
import CompletedPOs from '../../Assests/Images/completedpurchases.svg'
import Axios from "axios";
import Base from "../Config/Base";
import { API_BASE_URL } from "../Config/Config";


export default function Dashboard() {

    const [userDataSession, setUserDataSession] = useState([]);
    const [dashboardTablesData, setDashboardTablesData] = useState([]);
    const [dashboardCountsData, setDashboardCountsData] = useState([]);

    useEffect(() => {
        const userDataString = sessionStorage.getItem("userData");
        if (userDataString) {
            const userData = JSON.parse(userDataString);
            setUserDataSession(userData);
            console.log(userData);
        } else {
            console.log("User data not found in sessionStorage");
        }
    }, []);

    useEffect(() => {
        const fetchDashboardData = async () => {
            const table_api = `${API_BASE_URL}Dashboard/SupplierDashboard?OrgId=${userDataSession.OrgId}&SupplierId=${userDataSession.Id}`;
            try {
                const tablesResponse = await Axios.get(table_api);
                setDashboardCountsData(tablesResponse.data.DashboardCounts);
                setDashboardTablesData(tablesResponse.data.RecentPOs);
                console.log(tablesResponse.data, "tableresposne.dataaaaa");
        
            } catch (error) {
                console.error(
                "Error fetching dashboard data:",
                error.response ? error.response.data : error.message
                );
            }
        };
    
        if (userDataSession && userDataSession.OrgId) {
          fetchDashboardData();
        }
    }, [userDataSession]);

    

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    return (
        <Base>
            <div className="page-wrapper ">
                <div className="content">
                    <div className="welcome d-lg-flex align-items-center justify-content-between" style={{ marginTop: '-5rem' }}>
                        <div className="d-flex align-items-center welcome-text">
                            <h3 className="d-flex align-items-center">
                                <img src="assets/img/icons/hi.svg" alt="img" />
                                &nbsp;Hi {userDataSession.SupplierName}
                            </h3>
                            &nbsp;
                            <h6>here&apos;s an overview of your latest updates..</h6>
                        </div>
                    </div>

                    {/* Cards */}
                    <div className="row sales-cards justify-content-evenly">
                        <div className="col-xl-3 col-sm-6 col-6">
                            <Link to="/dashboard">
                                <div className="card color-info bg-primary mb-4">
                                    <img src={PendingPayment} alt="img" style={{ width: "2.7rem" }} />
                                    <h4 className="text-white">₹ {dashboardCountsData[0]?.PendingAmt || 0}</h4>
                                    <p>Pending Payment</p>
                                </div>
                            </Link>
                        </div>
                        
                        <div className="col-xl-3 col-sm-6 col-6">
                            <Link to="/dashboard">
                                <div className="card color-info bg-success mb-4">
                                    <img src={PaidPayment} alt="img" style={{ width: "2.7rem" }} />
                                    <h4 className="text-white">₹ {dashboardCountsData[0]?.PaidAmount || 0}</h4>
                                    <p>Paid Payment</p>
                                </div>
                            </Link>
                        </div>

                        <div className="col-xl-3 col-sm-6 col-6">
                            <Link to="/dashboard">
                                <div className="card color-info bg-info mb-4">
                                    <img src={PurchaseServed} alt="img" style={{ width: "2.7rem" }} />
                                    <h4 className="text-white">{dashboardCountsData[0]?.POcount || 0}</h4>
                                    <p>No.of PO's Served</p>
                                </div>
                            </Link>
                        </div>
                        
                        <div className="col-xl-3 col-sm-6 col-6">
                            <Link to="/dashboard">
                                <div className="card color-info bg-secondary mb-4">
                                    <img src={CompletedPOs} alt="img" style={{ width: "2.7rem" }} />
                                    <h4 className="text-white">{dashboardCountsData[0]?.CompletedPOs || 0}</h4>
                                    <p>Completed PO's</p>
                                </div>
                            </Link>
                        </div>
                    </div>

                    {/* Tables */}
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-xl-12 d-flex">
                            <div className="card flex-fill default-cover w-100 mb-4">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <h4 className="card-title mb-0 text-info">
                                        <i className="fa-solid fa-cubes me-2"></i>
                                        Recent Requests
                                    </h4>
                                    <div className="dropdown">
                                        <Link to="/dashboard" className="view-all d-flex align-items-center">
                                            <span className="ps-2 d-flex align-items-center">
                                                <i className="fa-solid fa-ellipsis text-info"></i>
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive" style={{ height: '20rem', overflowY: 'auto' }}>
                                        <table className="table table-borderless best-seller">
                                            <thead>
                                                <tr>
                                                    <th>S.No</th>
                                                    <th>PO Number</th>
                                                    <th>PO Date</th>
                                                    <th>Paid Amount</th>
                                                    <th>Due Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dashboardTablesData && dashboardTablesData.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>PO-{item.AutoGenTransactionNo}</td>
                                                        <td>{formatDate(item.PODate)}</td>
                                                        <td>{item.PaidAmount ? item.PaidAmount : 0}</td>
                                                        <td>{(item.InvoiceAmount) - (item.PaidAmount)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Base>
    );
}
